import store from 'store2';
import _omitBy from 'lodash/omitBy';
import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import i18next from 'i18next';
import { getUserDataTimeline } from '../trials/getUserData';
import { enterFullscreen } from '../trials/fullScreen';
import { corpusLetterAll } from './corpus';

import { computedScoreCallback, normedScoreCallback } from '../scores';
import { jsPsych } from '../jsPsych';

const makePid = () => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 16; i += 1) text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

const initStore = (config) => {
  if (store.session.has('initialized') && store.local('initialized')) {
    return store.session;
  }

  if (
    // future adaptive modes
    config.userMode === 'fullAdaptive' ||
    config.userMode === 'shortAdaptive'
  ) {
    // use adaptive algorithm to select next item
    store.session.set('itemSelect', 'mfi');
  } else if (
    config.userMode === 'fullRandom' ||
    config.userMode === 'testRandom' ||
    config.userMode === 'demo' ||
    config.userMode === 'shortLetter' ||
    config.userMode === 'blockConfig'
  ) {
    // random selection
    store.session.set('itemSelect', 'random');
  } else {
    // eslint-disable-next-line no-console
    console.warn(`add ${config.userMode} to initStoreLetter`);
    store.session.set('itemSelect', 'random');
  }

  // Counting variables
  store.session.set('practiceIndex', 0);
  store.session.set('currentBlockIndex', 0); // counter for breaks within subtask
  store.session.set('subTaskName', ''); // init to "" so getNextSubTask will work

  store.session.set('trialNumSubtask', 0); // counter for trials in subtask
  store.session.set('trialNumTotal', 0); // counter for trials in experiment

  // variables related to stimulus and response
  store.session.set('nextStimulus', null);
  store.session.set('response', '');

  // variables to track current state of the experiment
  store.session.set('currentTrialCorrect', true); // return true or false
  store.session.set('coinTrackingIndex', 0);
  store.session.set('maxTimeReached', false);

  // running computations
  store.session.set('subtaskCorrect', 0);
  store.session.set('totalCorrect', 0);
  store.session.set('totalPercentCorrect', 0);
  store.session.set('correctItems', []);
  store.session.set('incorrectItems', []);
  store.session.set('lowerCorrectItems', []);
  store.session.set('lowerIncorrectItems', []);
  store.session.set('upperCorrectItems', []);
  store.session.set('upperIncorrectItems', []);
  store.session.set('phonemeCorrectItems', []);
  store.session.set('phonemeIncorrectItems', []);

  // working copy of the three corpuses (items are removed as they are used)
  store.session.set('corpusLetterAll', corpusLetterAll);

  // this should be the last set before return
  store.session.set('initialized', true);
  return store.session;
};

// Stimulus timing options in milliseconds
const stimulusTimeOptions = [null, 350, 1000, 2000];
// Fixation presentation time options in milliseconds
const fixationTimeOptions = [1000, 2000, 25000];
// Trial completion time options in milliseconds
const trialTimeOptions = [null, 5000, 8000, 100000];

// get size of pratice blocks
export const getPracticeCount = (practiceType) => {
  const stimulusCountMap = {
    // this table is indexed by practiceType and returns a list with the number of trials in each block
    // userMode: [block1, block2, ...blockN]
    letter: [2],
    phoneme: [2],
    phonics: [2],
  };

  return stimulusCountMap[practiceType];
};
/**
 * Parse the userMode to determine the number of blocks.
 *
 * If the userMode matches the pattern "blockConfig([x, y, z])", then
 * this function will extract the "x, y, z" parameters from the userMode
 * string and return them as an array of numbers. If it does not match
 * that pattern, it will default to [2].
 *
 * @param {string} userMode - The user mode
 * @returns {number[]} The number of stimuli in each block
 */
const blockNnumber = (userMode) => {
  if (userMode && userMode.includes('blockConfig(') && userMode !== undefined) {
    const [, extractedBlocks] = userMode.match(/\[(.*?)\]/) || [];
    if (extractedBlocks) {
      const blocks = extractedBlocks.split(',').map(Number);
      return blocks;
    }
  }
  return [2];
};

// get size of blocks within LetterLower and LetterUpper
export const getStimulusCountLetter = (userMode) => {
  const stimulusCountMap = {
    fullRandom: [5, 5, 5, 5, 6],
    testRandom: [3, 3],
    demo: [3, 3, 3],
    blockConfig: blockNnumber(userMode),
    shortLetter: [5], // 1 block of 5 letters
  };
  return stimulusCountMap[userMode && userMode.includes('blockConfig(') ? 'blockConfig' : userMode];
};

// get size of blocks within LetterPhoneme
export const getStimulusCountPhoneme = (userMode) => {
  const stimulusCountMap = {
    // this table is indexed by userMode and returns a list with the number of trials in each block
    // userMode: [block1, block2, ...blockN]
    // fullAdaptive: [5, 5, 5, 5, 6],    // future
    fullRandom: [8, 8, 8, 8, 6],
    testRandom: [3, 3], // 6 letters with 1 break for testing
    demo: [3, 3, 3], // 9 letters with 2 breaks
    shortLetter: [8, 8, 8, 8, 6],
    // Temporarily remove phoneme block for Spanish letter
    blockConfig: [13, 13, 12, 12, 12], // here it is hardcoded for letterPhoneme
  };
  return stimulusCountMap[userMode && userMode.includes('blockConfig(') ? 'blockConfig' : userMode];
};

// get size of blocks within LetterLower and LetterUpper
export const getStimulusCountTextSound = (userMode) => {
  const stimulusCountMap = {
    // this table is indexed by userMode and returns a list with the number of trials in each block
    // userMode: [block1, block2, ...blockN]
    // fullAdaptive: [8, 8, 8, 8, 7],    // future
    // Phonics One block 70 words
    fullRandom: [70],
    testRandom: [70], // 6 letters with 1 break for testing
    demo: [70], // 9 letters with 2 breaks
    blockConfig: blockNnumber(userMode),
  };
  return stimulusCountMap[userMode && userMode.includes('blockConfig(') ? 'blockConfig' : userMode];
};

export const initConfig = async (firekit, gameParams, userParams, displayElement) => {
  const cleanParams = _omitBy(_omitBy({ ...gameParams, ...userParams }, _isNull), _isUndefined);

  const {
    userMode,
    assessmentPid,
    labId,
    userMetadata = {},
    testingOnly,
    consent,
    audioFeedback,
    language = i18next.language,
    grade,
    skipInstructions,
    recruitment,
    story,
    task,
    maxTime, // maximum time for real trials in minutes
  } = cleanParams;

  if (language !== 'en') i18next.changeLanguage(language);

  const config = {
    userMode: userMode || 'fullRandom',
    pid: assessmentPid,
    labId,
    recruitment: recruitment || 'pilot',
    story: story ?? false,
    task: task ?? 'letter',
    userMetadata: { ...userMetadata, grade },
    testingOnly,
    consent: consent ?? true,
    audioFeedback: audioFeedback || 'neutral',
    language,
    skipInstructions: skipInstructions ?? false,
    // stimulusRuleList: stimulusRuleLists[userMode],
    stimulusCountList: task === 'phonics' ? getStimulusCountTextSound(userMode) : getStimulusCountLetter(userMode),
    totalTrialsPractice: 5,
    countSlowPractice: 2,
    nRandom: 5,
    maxTime: maxTime, // null defaults to no time limit
    timing: {
      stimulusTimePracticeOnly: stimulusTimeOptions[0], // null as default for practice trial only
      stimulusTime: stimulusTimeOptions[1],
      fixationTime: fixationTimeOptions[0],
      trialTimePracticeOnly: trialTimeOptions[0],
      trialTime: trialTimeOptions[0],
    },
    startTime: new Date(),
    firekit,
    displayElement: displayElement || null,
  };

  const updatedGameParams = Object.fromEntries(
    Object.entries(gameParams).map(([key, value]) => [key, config[key] ?? value]),
  );
  await config.firekit.updateTaskParams(updatedGameParams);

  if (config.pid !== null) {
    await config.firekit.updateUser({
      assessmentPid: config.pid,
      ...userMetadata,
    });
  }

  return config;
};

export const initRoarJsPsych = (config) => {
  if (config.displayElement) {
    jsPsych.opts.display_element = config.displayElement;
  }

  // Extend jsPsych's on_finish and on_data_update lifecycle functions to mark the
  // run as completed and write data to Firestore, respectively.
  const extend = (fn, code) =>
    function () {
      // eslint-disable-next-line prefer-rest-params
      fn.apply(fn, arguments);
      // eslint-disable-next-line prefer-rest-params
      code.apply(fn, arguments);
    };

  jsPsych.opts.on_finish = extend(jsPsych.opts.on_finish, () => {
    config.firekit.finishRun();
  });

  jsPsych.opts.on_data_update = extend(jsPsych.opts.on_data_update, (data) => {
    if (data.save_trial) {
      config.firekit.writeTrial(data, computedScoreCallback, normedScoreCallback);
    }
  });

  // Add a special error handler that writes javascript errors to a special trial
  // type in the Firestore database
  window.addEventListener('error', (e) => {
    const { msg, url, lineNo, columnNo, error } = e;

    config.firekit?.writeTrial({
      task: 'error',
      lastTrial: jsPsych.data.getLastTrialData().trials[0],
      message: String(msg),
      source: url || null,
      lineNo: String(lineNo || null),
      colNo: String(columnNo || null),
      error: JSON.stringify(error || null),
      timeStamp: new Date().toISOString(),
    });
  });
  initStore(config);
};

export const initRoarTimeline = (config) => {
  // If the participant's ID was **not** supplied through the query string, then
  // ask the user to fill out a form with their ID, class and school.

  const initialTimeline = [enterFullscreen, ...getUserDataTimeline];

  const beginningTimeline = {
    timeline: initialTimeline,
    on_timeline_finish: async () => {
      // eslint-disable-next-line no-param-reassign
      config.pid = config.pid || makePid();
      await config.firekit.updateUser({
        assessmentPid: config.pid,
        labId: config.labId,
        ...config.userMetadata,
      });
    },
  };
  return beginningTimeline;
};
