/* eslint-disable import/no-cycle */
import store from 'store2';
import { getDevice } from '@bdelab/roar-utils';
import { Cat } from '@bdelab/jscat';

export const isTouchScreen = getDevice() === 'mobile';

// eslint-disable-next-line import/no-mutable-exports
export let cat;

export const initializeCat = () => {
  cat = new Cat({
    method: 'MLE',
    itemSelect: store.session('itemSelect'),
  });
};
