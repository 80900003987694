/* eslint-disable no-param-reassign */
import i18next from 'i18next';
import jsPsychAudioMultiResponse from '@jspsych-contrib/plugin-audio-multi-response';
import store from 'store2';
import { jsPsych } from '../jsPsych';
import { letterValidityEvaluator, mediaAssets } from '../experiment';
import { shuffle, updateProgressBar, addItemToSortedStoreList, replayAudioStimulus } from '../helperFunctions';
import { isPractice } from './subTask';
import { audioResponse } from './audioFeedback';
import { isMaxTimeoutReached } from './appTimer';

export const audioContext = new Audio();

const prepareLetterChoices = (target, distractors) => {
  // randomly select a location for the correct answer
  const randIndex = Math.floor(Math.random() * (distractors.length + 1));

  // randomize the order of the distractors
  const stimulus = shuffle(distractors);
  const choices = [];
  for (let i = 0; i < distractors.length; i += 1) {
    choices.push(stimulus[i]);
  }

  // insert the target
  choices.splice(randIndex, 0, target);

  return {
    target: target,
    choices: choices,
    correctResponseNum: randIndex,
  };
};

const letterNameTrials = {
  type: jsPsychAudioMultiResponse,
  response_allowed_while_playing: false,
  message_progress_bar: `${i18next.t('progressBar')}`,
  // inherit fields used in
  data: { save_trial: true },
  // play audio
  stimulus: () => {
    const stimulus = store.session.get('nextStimulus');

    return mediaAssets.audio[stimulus.audio_filename];
  },

  on_load: () => {
    // update the trial number
    store.session.transact('trialNumSubtask', (oldVal) => oldVal + 1);

    // update total real trials
    const subTaskName = store.session('subTaskName');
    if (!isPractice(subTaskName)) {
      store.session.transact('trialNumTotal', (oldVal) => oldVal + 1);
    }

    // set up replay button
    document.getElementById('replay').addEventListener('click', replayAudioStimulus);
  },
  // replay button
  prompt: () => `<img id="replay" draggable="false" src="${mediaAssets.images.iconSpeaker}" alt="replay"/>`,
  trial_duration: () => store.session.get('config').timing.trialTime,

  // arrange letter stimuli
  button_choices: () => {
    // Expirement logic should not be happening in trial parameters
    const stimulus = store.session.get('nextStimulus');
    let target = stimulus.target_letter;
    const d1 = stimulus.distractor1;
    const d2 = stimulus.distractor2;
    const d3 = stimulus.distractor3;

    // use the target letter for the button
    const trialInfo = prepareLetterChoices(target, [d1, d2, d3]);

    if (store.session.get('subTaskName') === 'Phonemes') {
      // save the name of the audio stimulus as the target
      // pulling the letter name from the audio filename is a bit of a hack
      // but it allows us to differentiate graphemes with two sounds (c,g,th,oo)
      target = stimulus.audio_filename.substring(0, stimulus.audio_filename.indexOf('Phoneme'));
    }

    store.session.set('target', target);
    store.session.set('correctResponseNum', trialInfo.correctResponseNum);
    store.session.set('choices', trialInfo.choices);

    return trialInfo.choices;
  },
  // display letter buttons
  button_html: () => '<button class="enable-btn">%choice%</button>',

  // check and store result
  on_finish: (data) => {
    // note: nextStimulus is actually the current stimulus
    // This variable is not used until we implement CAT
    // const nextStimulus = store.session('nextStimulus');
    const choices = store.session('choices');

    // get subtask name
    const subTaskName = store.session('subTaskName');

    // check response and record it
    data.correct = data.button_response === store.session('correctResponseNum') ? 1 : 0;
    data.assessment_stage = isPractice(subTaskName) ? 'practice_response' : 'test_response';
    store.session.set('correct', data.correct);
    store.session.set('response', data.button_response);
    store.session.set('responseValue', choices[data.button_response]);

    // Update engagement flags
    letterValidityEvaluator.addResponseData(data.rt, data.button_response, data.correct);

    // update running score and answer lists

    if (data.correct === 1) {
      addItemToSortedStoreList('correctItems', store.session('target'));
      store.session.transact('subtaskCorrect', (oldVal) => oldVal + 1);

      if (!isPractice(subTaskName)) {
        // practice trials don't count toward total
        store.session.transact('totalCorrect', (oldVal) => oldVal + 1);
      }
    } else {
      addItemToSortedStoreList('incorrectItems', store.session('target'));
    }

    const totalPercentCorrect = Math.round(
      (100 * store.session.get('totalCorrect')) / store.session.get('trialNumTotal'),
    );
    store.session.set('totalPercentCorrect', totalPercentCorrect);

    // update appropriate summary list
    const correctList = store.session('correctItems');
    const incorrectList = store.session('incorrectItems');
    if (subTaskName === 'LowercaseNames') {
      store.session.set('lowerCorrectItems', correctList);
      store.session.set('lowerIncorrectItems', incorrectList);
    } else if (subTaskName === 'UppercaseNames') {
      store.session.set('upperCorrectItems', correctList);
      store.session.set('upperIncorrectItems', incorrectList);
    } else if (subTaskName === 'Phonemes') {
      store.session.set('phonemeCorrectItems', correctList);
      store.session.set('phonemeIncorrectItems', incorrectList);
    }

    // update adaptive algorithm
    // cat.updateAbilityEstimate({a: 1, b: nextStimulus.difficulty, c: 0.25, d: 1}, store.session('response'));
    // console.log(cat.theta);
    // console.log(cat.thetaSE);

    // cat2.updateAbilityEstimate({a: 1, b: nextStimulus.difficulty, c: 0.5, d: 1}, store.session('response'));

    // save trial information
    jsPsych.data.addDataToLastTrial({
      // common to all trials
      pid: store.session.get('config').pid,
      subtask: subTaskName,
      corpusId: store.session('nextStimulus').corpus_src,

      // specific to this trial
      assessment_stage: isPractice(subTaskName) ? 'practice_response' : 'test_response',
      target: store.session('target'),
      choices: store.session('choices'),
      responseValue: store.session('responseValue'),
      responseNum: data.button_response,
      correctResponseNum: store.session('correctResponseNum'),

      correct: data.correct,
      replay: store.session('ifReplay'),
      subtaskCorrect: store.session('subtaskCorrect'),
      lowerCorrect: store.session('lowerCorrectItems'),
      lowerIncorrect: store.session('lowerIncorrectItems'),
      upperCorrect: store.session('upperCorrectItems'),
      upperIncorrect: store.session('upperIncorrectItems'),
      phonemeCorrect: store.session('phonemeCorrectItems'),
      phonemeIncorrect: store.session('phonemeIncorrectItems'),

      totalCorrect: store.session('totalCorrect'),
      trialNumSubtask: store.session('trialNumSubtask'),
      trialNumTotal: store.session('trialNumTotal'),
    });

    // reset the replay button
    store.session.set('ifReplay', 0);

    // progress bar
    if (!isPractice(subTaskName)) {
      updateProgressBar();
    }

    // log trial results for debugging
    // let temp = jsPsych.data.getLastTrialData();
    // console.log(temp.trials[0]);
  },
};

export const ifLetterNameTest = {
  timeline: [letterNameTrials],

  conditional_function: () => {
    // don't play if app timer expired
    if (isMaxTimeoutReached()) {
      return false;
    }
    return true;
  },
};

export const ifRealTrialResponse = {
  timeline: [audioResponse],

  conditional_function: () => {
    // don't play if app timer expired
    if (isMaxTimeoutReached()) {
      return false;
    }

    // doesn't apply to practice trials
    const subTaskName = store.session('subTaskName');
    return !isPractice(subTaskName);
  },
};
