import { RoarAppkit, initializeFirebaseProject } from '@bdelab/roar-firekit';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import RoarABC from '../src/experiment/index';
import { roarConfig } from './firebaseConfig';
// Import necessary for async in the top level of the experiment script
import 'regenerator-runtime/runtime';

const queryString = new URL(window.location).search;
const urlParams = new URLSearchParams(queryString);
const recruitment = urlParams.get('recruitment');
const userMode = urlParams.get('mode');
const assessmentPid = urlParams.get('PROLIFIC_PID') || urlParams.get('participant');
export const labId = urlParams.get('labId');
const audioFeedback = urlParams.get('feedback');

const skipInstructions = urlParams.get('skip')?.toLocaleLowerCase() === 'true';
const consent = urlParams.get('consent')?.toLocaleLowerCase() !== 'false';
const story = urlParams.get('story')?.toLocaleLowerCase() === 'true';
const task = urlParams.get('task') ?? 'letter';
const grade = urlParams.get('grade');
const maxTime = urlParams.get('maxTime') === null ? null : parseInt(urlParams.get('maxTime'), 10); // time limit for real trials

// Not using these in other apps, remove them?
const schoolId = urlParams.get('schoolId');
const studyId = urlParams.get('studyId');
const classId = urlParams.get('classId');

// @ts-ignore
const appKit = await initializeFirebaseProject(roarConfig.firebaseConfig, 'assessmentApp', 'none');

onAuthStateChanged(appKit.auth, (user) => {
  if (user) {
    const userInfo = {
      assessmentPid,
      assessmentUid: user.uid,
      userMetadata: {
        classId,
        schoolId,
        districtId: '',
        studyId,
      },
    };

    const userParams = {
      grade,
      assessmentPid,
      labId,
    };

    const gameParams = {
      userMode,
      skipInstructions,
      consent,
      audioFeedback,
      story,
      task,
      recruitment,
      maxTime,
    };

    const taskInfo = {
      taskId: task,
      variantParams: gameParams,
    };

    const firekit = new RoarAppkit({
      firebaseProject: appKit,
      taskInfo,
      userInfo,
    });

    const roarApp = new RoarABC(firekit, gameParams, userParams);

    roarApp.run();
  }
});

await signInAnonymously(appKit.auth);
