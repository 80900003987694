// Import necessary for async in the top level of the experiment script
import 'regenerator-runtime/runtime';
import store from 'store2';
import i18next from 'i18next';
import {
  ValidityEvaluator,
  createEvaluateValidity,
  createPreloadTrials,
  generateAssetObject,
} from '@bdelab/roar-utils';
import webpAssets from '../../webpAssets.json';
// setup
import {
  getStimulusCountLetter,
  getStimulusCountPhoneme,
  getPracticeCount,
  initRoarJsPsych,
  initRoarTimeline,
  getStimulusCountTextSound,
} from './config/config';
import { jsPsych } from './jsPsych';
import { initializeCat } from './experimentSetup';

// trials
import { ifLetterNameTest, ifRealTrialResponse } from './trials/stimulusLetterName';
import {
  setupLetterLowerTrial,
  setupLetterUpperTrial,
  setupLetterPracticeTrial,
  setupLetterPhonemeTrial,
  setupLetterPhonemePracticeTrial,
  setupLetterTextSoundPseudoTrial,
  setupPhonicsPracticeTrial,
} from './trials/setupLetterFixation';

import { exitFullscreen } from './trials/fullScreen';
import {
  subTaskInitLetterPractice,
  subTaskInitLowercase,
  subTaskInitUppercase,
  subTaskInitPhonemePractice,
  subTaskInitPhoneme,
  subTaskComplete,
  subTaskInitPhonicsPractice,
  subTaskInitTextSoundPseudo,
} from './trials/subTask';

import { ifPracticeCorrect, ifPracticeIncorrect, createPracticeTrials } from './trials/practice';
import {
  letterIntroAndInstructions,
  letterPracticeDone,
  letterTransition,
  soundIntroAndInstructions,
  soundPracticeDone,
  endTrial,
  storyBreakList,
  phonicsIntroAndInstructions,
  phonicsYoureReady,
  phonicsAllDone,
  createStory,
} from './trials/storySupport';

import { startAppTimer, clearAppTimer, isMaxTimeoutReached } from './trials/appTimer';

import enLetterNameLower from '../stimuli/en/letterNameLower.csv';
import enLetterNameUpper from '../stimuli/en/letterNameUpper.csv';
import enLetterNamePractice from '../stimuli/en/letterNamePractice.csv';
import enLetterPhoneme from '../stimuli/en/letterPhoneme.csv';
import enLetterPhonemePractice from '../stimuli/en/letterPhonemePractice.csv';
import enLetterTextSoundPseudo from '../stimuli/en/textSoundPseudo.csv';
import enStoryLion from '../stimuli/en/storyLion.csv';
import enStoryLionAge12 from '../stimuli/en/storyLionAge12.csv';
import enStoryPhonics from '../stimuli/en/storyPhonics.csv';
import enPracticePhonics from '../stimuli/en/practicePhonics.csv';
import esLetterNameLower from '../stimuli/es/letterNameLower.csv';
import esLetterNameUpper from '../stimuli/es/letterNameUpper.csv';
import esLetterNamePractice from '../stimuli/es/letterNamePractice.csv';
import esLetterPhoneme from '../stimuli/es/letterPhoneme.csv';
import esLetterPhonemePractice from '../stimuli/es/letterPhonemePractice.csv';
import esLetterTextSoundPseudo from '../stimuli/es/textSoundPseudo.csv';
import esStoryLion from '../stimuli/es/storyLion.csv';

const esStoryLionAge12 = esStoryLion;

// eslint-disable-next-line import/no-mutable-exports
export let letterValidityEvaluator;
// eslint-disable-next-line import/no-mutable-exports
export let mediaAssets;
// eslint-disable-next-line import/no-mutable-exports
export let preloadTrials;

const bucketURI = 'https://storage.googleapis.com/roar-ak';

export function buildExperiment(config) {
  // Add media assest here

  // TODO: Check back here in Letter task breaks
  preloadTrials = createPreloadTrials(webpAssets, bucketURI).default;
  preloadTrials.message = i18next.t('loading');
  mediaAssets = { audio: {}, images: {}, video: {} };
  const generateAssets = generateAssetObject(webpAssets, bucketURI, i18next.language);

  function destructureAssetList(file) {
    return {
      audio: file.map((audio) => audio.audioUrl),
      images: file.map((image) => image.imageUrl),
      video: file.map((video) => video.videoUrl),
    };
  }

  function addToMediaAssets(files) {
    files.forEach((assetList) => {
      const preloadedAssets = destructureAssetList(assetList);

      ['audio', 'images', 'video'].forEach((type) => {
        if (preloadedAssets[type]) {
          preloadedAssets[type]
            .filter((f) => f !== undefined && typeof f === 'string' && f.startsWith('http'))
            .forEach((asset) => {
              const fileName = asset.split('/').pop().split('.')[0];
              mediaAssets[type][fileName] = asset;
            });
        }
      });
    });
  }

  function getFiles() {
    let files = [];
    if (i18next.language === 'es') {
      files = [
        esLetterNameLower,
        esLetterNamePractice,
        esLetterNameUpper,
        esLetterPhoneme,
        esLetterPhonemePractice,
        esLetterTextSoundPseudo,
        esStoryLion,
        esStoryLionAge12,
      ];
    } else {
      files = [
        enLetterNameLower,
        enLetterNamePractice,
        enLetterNameUpper,
        enLetterPhoneme,
        enLetterPhonemePractice,
        enLetterTextSoundPseudo,
        enStoryLion,
        enStoryLionAge12,
        enStoryPhonics,
        enPracticePhonics,
      ];
    }
    return files;
  }

  addToMediaAssets(getFiles());

  function addGenerateAssetsToMedia() {
    ['audio', 'images', 'video'].forEach((type) => {
      if (generateAssets[type]) {
        Object.entries(generateAssets[type]).forEach(([fileName, asset]) => {
          mediaAssets[type][fileName] = asset;
        });
      }
    });
  }

  addGenerateAssetsToMedia();

  function addToPreloadTrial() {
    ['audio', 'images', 'video'].forEach((type) => {
      preloadTrials[type] = preloadTrials[type].concat(Object.values(mediaAssets[type]));
    });
  }

  addToPreloadTrial();

  // Initialize jsPsych and timeline
  initRoarJsPsych(config);
  const initialTimeline = initRoarTimeline(config);
  const letterEvaluateValidity = createEvaluateValidity({
    responseTimeThreshold: 600,
    accuracyThreshold: 0.6,
    minResponsesRequired: 5,
    includedReliabilityFlags: [],
  });

  const letterHandleEngagementFlags = (flags, reliable) => {
    // eslint-disable-next-line no-console
    // console.log('Flags triggered: ', flags);
    // eslint-disable-next-line no-console
    // console.log('Reliable: ', reliable);
    if (config.firekit.run.started) {
      return config.firekit?.updateEngagementFlags(flags, reliable);
    }
    // eslint-disable-next-line no-console
    return console.log('Run not started.');
  };

  letterValidityEvaluator = new ValidityEvaluator({
    evaluateValidity: letterEvaluateValidity,
    handleEngagementFlags: letterHandleEngagementFlags,
  });

  createStory();
  createPracticeTrials();

  const timeline = [preloadTrials, ...initialTimeline.timeline];

  // this function adds all the trials in a subtask (and the mid-subtask breaks) to the timeline
  // fixationBlock:  an array of fixation trials (to fetch next stimulus) configured in stimulusLetterName.js
  // stimulusCounts: an array of numbers, each entry defines the number of trials before a mid-subtask break

  let breakNum = 0;
  const pushSubTaskToTimeline = (subTaskInitBlock, fixationBlock, stimulusCounts) => {
    // begin the subtask
    timeline.push(subTaskInitBlock);

    // loop through the list of trials per block within the subtest
    for (let i = 0; i < stimulusCounts.length; i += 1) {
      // add trials to the block (this is the core procedure for each trial)
      const letterBlock = {
        timeline: [fixationBlock, ifLetterNameTest, ifPracticeCorrect, ifPracticeIncorrect, ifRealTrialResponse],
        conditional_function: () => {
          // check for app timeout
          if (isMaxTimeoutReached()) {
            return false;
          }

          if (stimulusCounts[i] === 0) {
            return false;
          }
          store.session.set('currentBlockIndex', i);
          return true;
        },
        repetitions: stimulusCounts[i],
      };

      timeline.push(letterBlock);
      // add breaks
      if (i + 1 !== stimulusCounts.length) {
        //  // no break on the last block of the subtask
        //   timeline.push(letterBlock);
        // } else {
        // add stimulus and break
        timeline.push(storyBreakList[breakNum]);
        breakNum += 1;
        if (breakNum === storyBreakList.length) {
          breakNum = 0;
        }
      }
    }

    // end of the subtask
    timeline.push(subTaskComplete);
  };

  initializeCat();

  // start the timer that limits the runtime of the app
  timeline.push(startAppTimer);

  if (config.task === 'phonics') {
    // phonics (was text sound pseudo)

    // intro
    timeline.push(phonicsIntroAndInstructions);

    // phonics practice
    pushSubTaskToTimeline(subTaskInitPhonicsPractice, setupPhonicsPracticeTrial, getPracticeCount('phonics'));

    timeline.push(phonicsYoureReady);

    // main test
    pushSubTaskToTimeline(
      subTaskInitTextSoundPseudo,
      setupLetterTextSoundPseudoTrial,
      getStimulusCountTextSound(config.userMode),
    );

    timeline.push(phonicsAllDone);
  } else {
    // Letter
    // intro
    timeline.push(letterIntroAndInstructions);

    // letter Practice
    pushSubTaskToTimeline(subTaskInitLetterPractice, setupLetterPracticeTrial, getPracticeCount('letter'));

    timeline.push(letterPracticeDone);

    // lowercase
    pushSubTaskToTimeline(subTaskInitLowercase, setupLetterLowerTrial, getStimulusCountLetter(config.userMode));

    // uppercase
    timeline.push(letterTransition);
    pushSubTaskToTimeline(subTaskInitUppercase, setupLetterUpperTrial, getStimulusCountLetter(config.userMode));

    // letter practice
    timeline.push(soundIntroAndInstructions);
    pushSubTaskToTimeline(subTaskInitPhonemePractice, setupLetterPhonemePracticeTrial, getPracticeCount('phoneme'));
    timeline.push(soundPracticeDone);

    // letter
    pushSubTaskToTimeline(subTaskInitPhoneme, setupLetterPhonemeTrial, getStimulusCountPhoneme(config.userMode));
    // goodbye
    timeline.push(endTrial);
  }

  // cleanup
  timeline.push(clearAppTimer);
  timeline.push(exitFullscreen);

  return { jsPsych, timeline };
}
